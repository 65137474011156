import Api from './api/topPageApi';

const getListNews = async() => {
    let storage = localStorage.getItem("user")
    const user = storage !== null ? JSON.parse(storage) : null;
    if(user == null) window.location.href = '/login';
    if ((user.access_token !== undefined && user.access_token !== null) || (user.session_token !== undefined && user.session_token !== null) ){
        try {
            const response=await Api().get('news', { headers: {
                Authorization: `Bearer ${user.access_token}`,
                accept: "application/json",
            } });
            return response.data;
        } catch (err) {
            window.location.href = '/login';
            return null
        }
    } else {
        window.location.href = '/login';
    }
}

const getRequest = async() => {
    let storage = localStorage.getItem("user")
    const user = storage !== null ? JSON.parse(storage) : null;
    if ((user.access_token !== undefined && user.access_token !== null) || (user.session_token !== undefined && user.session_token !== null) ){
        try {
            const response=await Api().get('requests', { headers: {
                Authorization: `Bearer ${user.access_token}`,
                accept: "application/json"
            } });
            return response.data;
        } catch (err) {
            return null
        }
    } else {
        window.location.href = '/login';
    }
}
  
const getProgress = async() => {
    let storage = localStorage.getItem("user")
    const user = storage !== null ? JSON.parse(storage) : null;
    if ((user.access_token !== undefined && user.access_token !== null) || (user.session_token !== undefined && user.session_token !== null) ){
        try {
            const response=await Api().get('progress', { headers: {
                Authorization: `Bearer ${user.access_token}`,
                accept: "application/json"
            } });
            return response.data;
        } catch (err) {
            return null
        }
    } else {
        window.location.href = '/login';
    }
}

const topPageService = {
    getListNews,
    getRequest,
    getProgress
};

export default topPageService;
